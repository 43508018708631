<script setup>
import DesktopGuidesMenuHeader from './header/menu-header.vue';

const appId = useRuntimeConfig().public.appId;
</script>

<template>
  <header>

    <div class="bg-white shadow w-full py-4">
        <div class="container mx-auto max-w-7xl px-4 sm:px-2 lg:px-8">
            <div class=" flex justify-between items-center">
                <NuxtLink :to="localePath({ name: 'home' })" class="flex"><img class="w-auto lg:h-5 sm:h-3" :src="`/images/${appId}/logo-blue.webp`" alt="Vartur"></NuxtLink>
                <div class="flex">
                    <NuxtLink :to="localePath({ name: 'guides.index' })" class="flex text-2xl">{{ $t('client.area_guides') }}</NuxtLink>
                </div>
                <div class="flex items-center">
                    <DesktopGuidesMenuHeader></DesktopGuidesMenuHeader>
                </div>
            </div>
        </div>
    </div>
  </header>

  <slot></slot>
</template>